import React, { useState, useEffect } from "react";

export default function NavBarEmptyHeader() {
  const [navHeight, setNavHeight] = useState(0)

  useEffect(() => {
    const Timer = setInterval(() => {
      const navbar = document.querySelector(".navbar")
      if (navbar) {
        const NavBarHeight = navbar.clientHeight
        setNavHeight(NavBarHeight)
      }

    }, 100);
    return (() => {
      clearInterval(Timer)
    })
  }, [navHeight])

  const style = {
    visibility: "hidden",
    height: navHeight
  }
  return (<div className="EmptyBox" style={style}></div>)
}
