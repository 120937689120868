import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import notifs from '../../imgs/notifs.png'
//import { MyContext } from '../Context/test'
import { NotificationContext } from '../Context/notificationsContext'
export default function NotificationsBadge() {
    //🙌 i did it
    //const { id, setId } = useContext(MyContext)

    const { setNotifications, notifCount, setNotifCount } = useContext(NotificationContext);
    const [countTry, setCountTry] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => {
            setCountTry(countTry + 1)
        }, 15 * 1000)
        axios.get(`${process.env.REACT_APP_ROOT_BACKEND}/att/notifications.php?testGame=${process.env.REACT_APP_DEV_Key}`
            , {
                withCredentials: true,
                credentials: true
            })
            .then(
                (res) => {
                    if (res.data) {
                        res.data = JSON.stringify(res.data)
                        const matches = res.data.match(/\[.+?\]/)
                        res.data = JSON.parse(matches[0])
                        setNotifications(res.data)
                        const NotifNum = res.data.reduce(
                            (unseenSum, Notif) => Notif.seen === '0' ? unseenSum + 1 : unseenSum + 0, 0
                        )
                        setNotifCount(NotifNum)
                    } else {
                        setNotifCount(0)
                    }
                }
            )
        return () => clearInterval(timer)
    }, [countTry, setNotifCount, setNotifications])

    return (
        <span>
            <Link to={`${process.env.PUBLIC_URL}/notifications`}>
                <img
                    src={notifs}
                    alt="Notifications"
                    className="w-6"
                />
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/notifications`} style={{ textDecoration: "none" }}>

                <span className={(notifCount ? "hasNotif" : "noNotif")} style={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    marginLeft: "-12px",
                    bottom: "30px",
                    position: "relative",
                    fontSize: "10pt",
                    right: "-12px"
                }}>
                    &nbsp;{notifCount}&nbsp;
                </span>
            </Link>
        </span >
    )
}
