import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NotificationsBadge from '../Notifications/NotificationsBadge'
import Profile from '../Profile/NavProfile'
import userPhotos from '../../imgs/userPhotos.png'
import infoPhoto from '../../imgs/info2.png'
import AddPhoto from '../../imgs/AddPhoto.png'
import AnsweredLikedPhoto from '../../imgs/ListOfAnswers.png'

//import NavBarEmptyHeader from '../GlobalParts/navBarEmptyHeader'
import NavProgressBar from '../Profile/NavProgressBar'
import ProfileTut from '../Tutorials/ProfileTut'
import WelcomeTut from '../Tutorials/WelcomeTut'
import UploadPhotoTut from '../Tutorials/UploadPhotoTut'

export default function NavTailwind() {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <>
            <ProfileTut />
            <UploadPhotoTut />
            <WelcomeTut show={showInfo} setShow={setShowInfo} />
            <nav className="relative flex flex-wrap items-center justify-between px-2 bg-gray-700 ">
                <div className="container py-1 mx-auto flex flex-wrap items-center justify-between">
                    <div className="relative flex w-auto  pl-4  justify-start ">
                        <Link className="navbar-item text-sm font-bold leading-relaxed inline-block mr-4 py-0 whitespace-nowrap uppercase text-white" to={`${process.env.PUBLIC_URL}/`}>
                            <img src="https://howoldilook.com/loading-logo.png?type=static" className="w-7" alt="Logo" />
                        </Link>
                        <div className="text-white mt-1 hidden sm:block">
                            How old i look ?
                        </div>
                    </div>
                    <div className="flex flex-grow items-center" id="example-navbar-warning">
                        <ul className="flex  flex-row list-none ml-auto">
                            <li className="nav-item">
                                <span className="px-2 py-0  top-2 relative flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                                    <Profile />
                                </span>
                            </li>

                            <li className="nav-item">
                                <span className="px-2 py-0  top-2 relative hidden items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                                    <Link to={`${process.env.PUBLIC_URL}/MyPhotos`}>
                                        <img className="w-9" src={AnsweredLikedPhoto} alt="" />
                                    </Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="px-2 py-0  top-3.5 relative flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                                    <NotificationsBadge />
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="px-2 py-0  top-2.5 relative flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                                    <Link to={`${process.env.PUBLIC_URL}/MyPhotos`}>
                                        <img className="w-7 h-7" src={userPhotos} alt="" />
                                    </Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="addPhoto px-2 py-0  top-2.5 relative flex items-center text-xs uppercase font-bold leading-snug text-white top hover:opacity-75">
                                    <Link to={`${process.env.PUBLIC_URL}/upload`}>
                                        <img className="w-7 h-7" src={AddPhoto} alt="" />
                                    </Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="px-2 py-0  top-3 relative flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                                    <img className="w-7 h-7" src={infoPhoto} alt=""
                                        onClick={() => {
                                            setShowInfo(true)
                                        }} />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <NavProgressBar />
        </>
    )
}
