const Levels = [
    ////this makes the array index match the level user is in.
    {},
    {
        Level: 1, minScore: 0, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 2, minScore: 200, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 3, minScore: 600, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 4, minScore: 1200, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 5, minScore: 1700, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 6, minScore: 2400, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 7, minScore: 2800, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 8, minScore: 3800, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 9, minScore: 4300, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 10, minScore: 4800, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 11, minScore: 5400, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 12, minScore: 6200, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 13, minScore: 6900, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 14, minScore: 7800, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 15, minScore: 9300, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 16, minScore: 9700, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 17, minScore: 10500, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 18, minScore: 11700, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 19, minScore: 12700, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 20, minScore: 15700, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },
    {
        Level: 21, minScore: 70000, Logo: "Waving_Hand",
        TagLine: "We are happy to see you here "
    },


]

const findLevel = (score) => {
    return Levels.reduce((acc, cur, index, arr) => {
        return ((score <= cur.minScore && score > (arr[index - 1].minScore || -1)) ? { ...arr[index - 1], maxScore: arr[index].minScore } : acc)
    })
}
export { Levels as LevelsData }
export default findLevel

