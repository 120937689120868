import React, { useEffect, useContext } from 'react'
import { LoginContext } from '../Context/userLoginContext'
import { Link } from 'react-router-dom'

export default function NavProfile() {
    const { profilePictureSmall } = useContext(LoginContext)
    useEffect(() => {

    }, [])
    return (
        <Link to={`${process.env.PUBLIC_URL}/profile`}>
            <span style={{
                marginRight: 10
            }}
                className="NavProfile"
            >
                <img
                    src={decodeURIComponent(profilePictureSmall)} alt=""
                    style={{
                        padding: 1,
                        backgroundColor: "#fff",
                        border: "1px solid grey",
                        height: 30,
                        width: 30,
                        borderRadius: "50%",
                    }}
                />
            </span>
        </Link>
    )
}

