import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'


ReactDOM.render(
  <Suspense fallback="">
    <Router>
      <App />
    </Router>
  </Suspense>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(consolei.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
