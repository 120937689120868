import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../Context/userLoginContext'
import findLevel from '../Profile/ScoresToLevels'
import { toast } from 'react-toastify'

/*
The formula is : 
$score = $percentOff > 35 ? 0 : intval(((36 - $percentOff) / 36) * 100);

*/
export default function NavProgressBar() {
    const { score, name } = useContext(LoginContext)
    const Level = findLevel(score)
    const barWidth = ((score - Level.minScore) / (Level.maxScore - Level.minScore)) * 100
    const [barAnimatedWidth, setBarAnimatedWidth] = useState(0)
    const [prevBarWidth, setPrevBarWidth] = useState(0)
    const [levelLoaded, setLevelLoaded] = useState(false)

    function easeInOutExpo(x) {
        return x === 0
            ? 0
            : x === 1
                ? 1
                : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2
                    : (2 - Math.pow(2, -20 * x + 10)) / 2;
    }

    useEffect(() => {

        // let progress = 0
        // setTimeout(() => {
        //     const Interval = setInterval(() => {
        //         progress += 0.007
        //         const barMovement = easeInOutExpo(progress) * (barWidth - prevBarWidth) + prevBarWidth
        //         setBarAnimatedWidth(barMovement)
        //     }, 10);
        //     setTimeout(() => {
        //         clearInterval(Interval)
        //         setPrevBarWidth(barWidth)
        //     }, 1500);
        // }, 1000)
        //eslint-disable-next-line
        console.log("Bar Width = " + barWidth)
    }, [score])

    useEffect(() => {
        if (Level?.Level && name) {
            setLevelLoaded(true)
        }
        if (levelLoaded) {
            toast("Level " + Level.Level, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        //eslint-disable-next-line
    }, [Level.Level])

    return (
        <div className="NavProgressBar bg-gradient-to-r from-green-400 to-blue-500" style={{
            width: `${barWidth}%`,
            height: 4,
            position: "relative"
        }}></div >
    )
}
