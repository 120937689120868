import React, { useContext, useEffect } from "react";
import { FacebookLoginButton } from "react-social-login-buttons";
import axios from "axios";
import { LoginContext } from "../../Context/userLoginContext";
import { useHistory } from "react-router-dom";

export default function FacebookLogin(props) {
  const { setLoggedIn, setRefetch, loggedIn } = useContext(LoginContext);
  const history = useHistory();

  useEffect(() => {
    if (props?.refComponent === "uploadImage" && loggedIn) {
      history.push(`${process.env.PUBLIC_URL}/upload`);
    }
  }, [loggedIn, props?.refComponent, history]);
  // Basic Setup
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FB_APP_ID,
      xfbml: true,
      version: "v10.0",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  function loginFb() {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          setLoggedIn(true);
          axios
            .get(
              `${process.env.REACT_APP_ROOT_BACKEND}/att/authentication.php?fbaccess_token=${response.authResponse.accessToken}`,
              {
                withCredentials: true,
                credentials: "include",
              }
            )
            .then((resp) => {
              setRefetch(1);
            });
        } else {
        }
      },
      { scope: "public_profile,email" }
    );
  }

  return (
    <div>
      <FacebookLoginButton onClick={loginFb} />
    </div>
  );
}
