import { useEffect } from "react"

export default function RemoveOldCache() {
    useEffect(() => {
        const removeHomeCache = async () => {
            window.caches?.keys().then(keys => {
                keys.forEach(key => {
                    caches.open(key)
                        .then((cache) => {
                            cache.keys()
                                .then(elems => {
                                    elems.forEach(elem => {
                                        if (elem.url === "https://howoldilook.com/") {
                                            cache.delete('/')
                                        }

                                    })

                                })
                        })
                })
            })
        }
        removeHomeCache()
    }, [])
    return (null)
}
