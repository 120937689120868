import React, { useEffect, useContext } from 'react'
import { TutorialContext } from '../Context/TutorialContext'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';


export default function ProfileTut() {
    const { tutorial, setTutorial } = useContext(TutorialContext)

    useEffect(() => {
        if (tutorial?.tutorialLoaded && !tutorial?.profileShow && 1 === 2) {
            tippy('.NavProfile', {
                content: 'This is your page, check it out!',
                showOnCreate: true,
                theme: 'translucent',
                onHide: () => {
                    setTutorial({ ...tutorial, profileShow: true })
                },
            })
        }
    }, [tutorial, setTutorial])
    return (<></>)
}
