import React, { useEffect, useContext } from 'react'
import { TutorialContext } from '../Context/TutorialContext'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';



export default function UploadPhotoTut() {
    const { tutorial, setTutorial } = useContext(TutorialContext)

    useEffect(() => {
        if (!tutorial?.addPhoto && tutorial.tutorialLoaded && tutorial.initialInfoClosed) {
            setTimeout(() => {
                tippy('.addPhoto', {
                    content: "Tap here to add your photo now and see how old you get guessed, it's fun and exciting 😉.",
                    showOnCreate: true,
                    onHide: () => {
                        setTutorial({ ...tutorial, addPhoto: true })
                    }
                })
            }, 1000);

        }
    }
        , [tutorial, setTutorial])
    return (<></>)
}
