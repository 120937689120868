import React from 'react'
import axios from 'axios'

export default function AllowNotifications(props) {
    const applicationServerKey =
        'BNMvkyx_z8OBsgECkdO2UuTm8m8G2xiRtSL5HJaMsrx6CoBZbrXYlbQ43mqexP43VrYhewXIEoFWBvcQUgCLSVs'
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
        // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray;
    }
    const AllowNotifFunc = async () => {
        props.OnClickFunction()
        if ("Notification" in window && !(/apple/i.test(navigator.vendor))) {
            Notification.requestPermission()
                .then(function (permission) {
                    if (permission === "granted") {
                        navigator.serviceWorker.ready
                            .then(ServiceWorkerRegistration => {
                                ServiceWorkerRegistration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey:
                                        urlBase64ToUint8Array(applicationServerKey),
                                }).then(subscription => {
                                    const subs = (JSON.parse(JSON.stringify(subscription)))
                                    axios.post(`${process.env.REACT_APP_ROOT_BACKEND}/Notifications/src/push_subscription.php`, {
                                        endpoint: subs.endpoint,
                                        authToken: subs.keys.auth,
                                        publicKey: subs.keys.p256dh,
                                        contentEncoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0]
                                    }, {
                                        withCredentials: true,
                                        credentials: 'include'
                                    })
                                })
                            }
                            )
                    }

                });
        }
    }
    return (
        <button
            className={props.variant ||
                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"}
            onClick={AllowNotifFunc} size="sm"
            type="submit"
        >
            Allow
        </button>
    )
}
