import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import React, { lazy, Suspense, useEffect } from 'react'
import NavBarEmptyHeader from '../GlobalParts/navBarEmptyHeader'
import Spinner from '../GlobalParts/Spinner'
import NavTailwind from '../NavBar/NavTailwind'

const loginUpload = lazy(() => import('../UploadImage/Login'))

const PhotoAnswers = lazy(() => import('../Answers'))
const MyPhotos = lazy(() => import('../MyPhotos/MyPhotos'))
const ImgList = lazy(() => import('../Answers/allList'))
const NotifiContainer = lazy(() => import('../Notifications/'))
const Upload = lazy(() => import('../UploadImage'))
const TheGame = lazy(() => import('../Game/Index'))
const Profile = lazy(() => import('../Profile/Index'))
const Tests = lazy(() => import('../Test/testComponent'))


export default function Routes() {

    useEffect(() => {
        document.querySelector('.MainPageSpinner')?.remove()
        window.addEventListener('selectstart', e => e.preventDefault())
    }, [])

    return (
        <Suspense fallback={
            <div align="center">
                <NavBarEmptyHeader /><br />
                <Spinner />
            </div>
        }>
            <Router basename={''}>
                <NavTailwind />
                <Switch>
                    <Route exact
                        path={`${process.env.PUBLIC_URL}/`}
                        component={TheGame}
                    />
                </Switch>
                <Switch>
                    <Route exact
                        path={`${process.env.PUBLIC_URL}/MyPhotos`}
                        component={MyPhotos}
                    />
                </Switch>
                <Switch>
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/notifications`}
                        component={NotifiContainer}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/singImage/:imageName`}
                        component={TheGame}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/ListOfAnswers/:id`}
                        component={PhotoAnswers}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/answersList`}
                        component={ImgList}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/upload`}
                        component={Upload}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/FaceBookLogin`}
                        component={loginUpload}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/v`}
                        component={TheGame}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/Profile`}
                        component={Profile}
                    />
                </Switch>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/Test8721321dna8e3d`}
                        component={Tests}
                    />
                </Switch>
            </Router>
        </Suspense>
    )
}
