import { useEffect, useContext } from 'react'
import axios from 'axios'
import { LoginContext } from '../Context/userLoginContext'

export default function NotifPermsDbCheck() {
    const { notifPerms } = useContext(LoginContext);
    const applicationServerKey =
        'BNMvkyx_z8OBsgECkdO2UuTm8m8G2xiRtSL5HJaMsrx6CoBZbrXYlbQ43mqexP43VrYhewXIEoFWBvcQUgCLSVs'
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
        // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray;
    }
    useEffect(() => {
        if (notifPerms?.length && "Notification" in window && !(/apple/i.test(navigator.vendor))) {
            Notification.requestPermission()
                .then(function (permission) {
                    if (permission === "granted") {
                        navigator.serviceWorker.ready
                            .then(ServiceWorkerRegistration => {
                                ServiceWorkerRegistration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey:
                                        urlBase64ToUint8Array(applicationServerKey),
                                }).then(subscription => {
                                    const subscriptionJson = JSON.parse(JSON.stringify(subscription))
                                    const p256dhKey = subscriptionJson.keys.p256dh;
                                    const lastP256dhKeyCharacters = p256dhKey.substr(p256dhKey.length - 5)
                                    let isPermInDb = false
                                    notifPerms.forEach(perm => {
                                        if (perm === lastP256dhKeyCharacters) {
                                            isPermInDb = true
                                        }
                                    })
                                    if (!isPermInDb) {
                                        axios.post(`${process.env.REACT_APP_ROOT_BACKEND}/Notifications/src/push_subscription.php`, {
                                            endpoint: subscriptionJson.endpoint,
                                            authToken: subscriptionJson.keys.auth,
                                            publicKey: subscriptionJson.keys.p256dh,
                                            contentEncoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0]
                                        }, {
                                            withCredentials: true,
                                            credentials: 'include'
                                        })
                                    }
                                })
                            }
                            )
                    }

                });
        }
    }, [notifPerms])
    return null
}
