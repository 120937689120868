import React, { useEffect, useContext, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TutorialContext } from '../Context/TutorialContext'
import AllowNotificationButton from '../Logics/AllowNotifications'
import FacebookLoginButton from '../Social/socialLoginButtons/facebookLogin'
import { LoginContext } from '../Context/userLoginContext'
export default function WelcomeTut(props) {
    const cancelButtonRef = useRef(null)
    const { tutorial, setTutorial } = useContext(TutorialContext)
    const { profilePictureSmall, name, loggedIn } = useContext(LoginContext)
    const handleClose = () => {
        props.setShow(false)
        setTutorial({ ...tutorial, initialInfoClosed: true })
    }

    useEffect(() => {
        if (tutorial.tutorialLoaded && !tutorial.initialInfo) {
            props.setShow(true)
            props.setShow(false)
            setTutorial({ ...tutorial, initialInfo: true })
        }
    }, [tutorial, setTutorial, props])


    return (
        <>
            <Transition.Root show={props.show} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={props.open}
                    onClose={handleClose}
                >
                    <div
                        style={{ maxHeight: '80vh' }}
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <div className="mt-2 overflow-y-auto font-serif" style={{ maxHeight: "75vh" }}>
                                                <h1 style={{ fontSize: "xx-large" }}>
                                                    ℹ️ Welcome to How old i look 🥳
                                                </h1>
                                                <span>
                                                    With "how old i look ?" you can upload your photo
                                                    for other users to guess your age, guess others age and see how people react to your guesses
                                                    <form style={{ display: "none" }}>
                                                        <label>
                                                            Name:
                                                            <input type="text" name="name" />
                                                        </label>
                                                        <input type="submit" value="Submit" />
                                                    </form>
                                                </span>
                                                {"Notification" in window && Notification?.permission === "default" ?
                                                    <div className="flex justify-start mt-5">
                                                        <div>
                                                            Allow notifications to know when people react to your guesses. : &nbsp;

                                                            <AllowNotificationButton OnClickFunction={() => {
                                                                document.querySelector('.InfoTutNotification').style.display = "none";
                                                            }} />
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {!loggedIn ?
                                                    <>
                                                        <div className="flex mt-4">
                                                            <div>
                                                                This is how we see you :
                                                            </div>
                                                            <div>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <img src={decodeURIComponent(profilePictureSmall)} className="inline" style={{ borderRadius: "50%", width: 30 }} alt="" />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-green-700">{name}</span><br /><br />
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <div>
                                                                Save your progress, change your name and profile picture by loging in :<br /><br />
                                                            </div>
                                                            <FacebookLoginButton />
                                                        </div>
                                                    </>
                                                    : ''}
                                                <br />
                                                <span className="text-lg font-bold">Privacy :</span>
                                                We use cookies and collect some data to function, by using this app you agree with our <a
                                                    className="text-blue-600"
                                                    href="https://howoldilook.com/att/miscFiles/privacypolicy.pdf"
                                                >privacy policy</a>.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() =>
                                            handleClose()
                                        }
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
