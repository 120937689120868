import { useEffect } from "react"

export default function InstallPromoteEventHandler() {
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            window.deferredPrompt = e
        })
    }, [])
    return (null)
}
