import { LoginContext } from './components/Context/userLoginContext'
import { TutorialContext } from './components/Context/TutorialContext'
import { PhotoContext } from './components/Context/PhotoDataContext'
import { NotificationContext } from './components/Context/notificationsContext'
import RemoveOldCache from './components/Logics/removeOldCache'
import InstallPromoteEventHandler from './components/Logics/InstallPromoteEventHandler'
import NotifPermsDbCheck from './components/Logics/NotifPermsDbCheck'
import React, { useState, lazy } from 'react'
import Routes from './components/GlobalParts/Routes'
import { ToastContainer } from 'react-toastify'
import './App.css';

window.baseurl = process.env.REACT_APP_ROOT_BACKEND

const LoginManager = lazy(() => import('./components/Logics/LoginManager'))
const TutorialContextManager = lazy(() => import('./components/Logics/TutorialContextManager'))



function App() {
  // this provider approach makes the sysmet much more Powerful  💪
  const [id, setId] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [profilePicture, setProfilePicture] = useState()
  const [profilePictureSmall, setProfilePictureSmall] = useState()
  const [name, setName] = useState()
  const [notifPerms, setNotifPerms] = useState([]);
  const [refetch, setRefetch] = useState(1);
  const [score, setScore] = useState(1);


  const [photoData, setPhotoData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [likeDoubleTap, setLikeDoubleTap] = useState(false);

  const [tutorial, setTutorial] = useState({});

  const [notifications, setNotifications] = useState([])
  const [notifCount, setNotifCount] = useState(0)

  return (
    <LoginContext.Provider value={{
      id, setId,
      profilePicture, setProfilePicture,
      name, setName,
      loggedIn, setLoggedIn,
      notifPerms, setNotifPerms,
      refetch, setRefetch,
      score, setScore,
      profilePictureSmall, setProfilePictureSmall,
    }}
    >
      <TutorialContext.Provider value={{ tutorial, setTutorial }}>
        <PhotoContext.Provider value={{ photoData, setPhotoData, counter, setCounter, likeDoubleTap, setLikeDoubleTap }}>
          <NotificationContext.Provider value={{
            notifications, setNotifications,
            notifCount, setNotifCount
          }}>

            <LoginManager />
            <TutorialContextManager />

            <Routes />


            <ToastContainer />
            <RemoveOldCache />
            <InstallPromoteEventHandler />
            <NotifPermsDbCheck />
          </NotificationContext.Provider>
        </PhotoContext.Provider>
      </TutorialContext.Provider>
    </LoginContext.Provider>
  );
}

export default App;
